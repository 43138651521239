import SEOComponent from '@/components/Misc/SEO'
import Footer from '@/components/Footer/Footer'
import PageSection from '../src/components/PageSection'
import { HOME_PAGE_NAME_PROPERTY, HOME_PAGE_VIEW_ANALYTICS_EVENT_NAME, useAmplitude } from '@/utils/hooks/useAmplitude'
import { useEffect } from 'react'
import { getHomepageApi } from '@/utils/api'
import { GetPageQuery } from '../graphql/gql/wrapper/graphql'
import Header from '@/components/Header/HeaderCommon'
import { CmsPageSlugObjType, getAllPagesSlugs } from '@/utils/cmsUtils'
import { windowExists } from '@/utils/windowUtils'
import { GetStaticPropsContext } from 'next'

export type SinglePageType = GetPageQuery['allMkMarketingPage'][0]
export type SinglePageTypeSEO = GetPageQuery['allMkMarketingPage'][0]['mk_seo']

type PageBuilderProps = {
  page: SinglePageType
  cmsSlugObj: CmsPageSlugObjType[]
}

const Index = ({ page, cmsSlugObj }: PageBuilderProps) => {
  const { mk_pageBuilder, mk_header, mk_footer, mk_seo } = page
  const cmsSlugSet = new Set(cmsSlugObj.map((slug) => slug?.mk_slug?.current))

  const { setCmsSlugSet, logAmplitudeEvent, setPage } = useAmplitude()

  useEffect(() => {
    if (window.location.pathname === '/') {
      // Send page view event to Amplitude
      // Will send event twice on localhost because of reactStrictMode config in next.config.js
      logAmplitudeEvent(
        HOME_PAGE_VIEW_ANALYTICS_EVENT_NAME,
        null,
        { 'Page Name': HOME_PAGE_NAME_PROPERTY },
        { isPageViewEvent: true },
      )

      // Set state for use in interaction events
      setPage(HOME_PAGE_NAME_PROPERTY)
      setCmsSlugSet(cmsSlugSet)
    }
  }, [windowExists() && window.location.href])

  const pageDetails = mk_pageBuilder?.map((pageItem, index) => <PageSection details={pageItem} key={index} />)

  return (
    <div>
      <SEOComponent seoData={{ mk_seo, slug: '' }} />
      <Header headerToDisplay={mk_header ?? 'None'} />

      {pageDetails}
      {mk_footer && <Footer />}
    </div>
  )
}

export const getStaticProps = async ({ preview: isPreview }: GetStaticPropsContext) => {
  let pageQueryResponse = await getHomepageApi(!!isPreview)
  let page = pageQueryResponse?.allMkHomePage[0]

  // If preview page no longer exists, get the actual page
  if (isPreview && !page) {
    pageQueryResponse = await getHomepageApi(false)
    page = pageQueryResponse.allMkHomePage[0]
  }

  const cmsSlugObj = await getAllPagesSlugs()

  if (!page) {
    return {
      notFound: true,
    }
  }

  return { props: { page, cmsSlugObj }, revalidate: 60 }
}

export default Index
